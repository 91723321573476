import React from 'react';
import './styles.css';

const Header = () => (
  <header className='home-header'>
    <img className='headerImg' src='./logo-alcopasa-blog.png' alt='LOGO-BLOG-ALCOPASA'/>
    <main>
    <text className='text'>RECETAS</text>
    </main>
    
  </header>
   
  
);

export default Header;
