import React, { Component, useEffect } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types'
import './styles.css';







export class EntradasItem extends Component {
    state = {
        imgUrl: '',
        author: '',
        isLoaded: false

    }
    
    static propTypes = {
        entradas: PropTypes.object.isRequired,
    }

    
    
  
    
    componentDidMount() {
        const { featured_media } = this.props.entradas;

        //constante idcategoria en la entrada y añadirle ese valor a la url del getCategorias

        // const idcategoria = this.props.entradas.categories
        // const getCategorias = axios.get(`/wp/v2/categories/${categories}`)
        const getImageUrl =  axios.get(`/wp/v2/media/${featured_media}`);

        Promise.all([getImageUrl,]).then(res => {
                

              this.setState({
                
            imgUrl: res[0].data.media_details.sizes.medium.source_url,
            isLoaded: true,
           
        })
    

     });
     


     
}



  
  render() {

    const { title, excerpt, categories} = this.props.entradas;
    
    
    // const { item,name } = this.props.categorias;
    
    const { imgUrl, } = this.state;

    //const idcategoria = categories
    if ( categories[0] === 20){
      return (
        //console.log('no es igual a 21'),

        <div className='blogItem-wrap'>
        <h2 style={{ marginBottom: '0'}}>{title.rendered}</h2>
        
        
       <img className='blogItem-cover' src={imgUrl} alt={title.rendered}/>
       <small className='chip'>
        
       <p>👨🏼‍🍳 RECETAS</p>
              
      </small>
          <p className='blogItem-desc' dangerouslySetInnerHTML= {{__html: excerpt.rendered }} />
      </div>
    
      
    )
   }else if (categories[0] === 21){
    return (
      //console.log('no es igual a 20'),
      <div className='blogItem-wrap'>
      <h2 style={{ marginBottom: '0'}}>{title.rendered}</h2>
      
      
     <img className='blogItem-cover' src={imgUrl} alt={title.rendered}/>
     <small className='chip'>
      
     <p>🥄UTENSILIOS</p>
            
    </small>
        <p className='blogItem-desc' dangerouslySetInnerHTML= {{__html: excerpt.rendered }} />
    </div>
  
    
  )
   }
    }
        
        
   
  }


export default EntradasItem