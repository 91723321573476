import React, { Component } from 'react'
import axios from 'axios'
import EntradasItem from './EntradasItem'


export class Entradas extends Component {
    state = {
        entradas: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get('/wp/v2/posts')
        .then(res => this.setState({
            entradas: res.data,
            isLoaded:true,
            
        }))
        .catch(err => console.log(err));

    }


  render() {
    const { entradas, isLoaded }= this.state;
    if(isLoaded){
        return (
          
            <div className='blogList-wrap'>
                 {entradas.map(item => ( 
                    <EntradasItem key={item.id} entradas={item} /> 
                    
                 ))}
                
            </div>
           
            
                   
                   

                
        );
    }
    return (
     
  
      
  <section className='loadcenter' >
    
   
  <div className="loading loading08">
  <span data-text="B">B</span>
  <span data-text="l">l</span>
  <span data-text="o">o</span>
  <span data-text="g">g</span>
  </div>
  
  
</section>
  
   
      
    )
  }
}

export default Entradas