import './App.css';
import Entradas from './BlogList/Entradas';


import Header from './components/Header';

function App() {
  return (
    <div className="container">
      <Header/>
     <Entradas/>
   
    </div>
  );
}

export default App;
